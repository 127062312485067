function busRaf() {
  gsap.ticker.add(onRafBis)

  function onRafBis() {
    bus.emit('bus.raf')
  }
}

export default busRaf


/*
  bus.on('bus.raf', someStuff)
  bus.off('bus.raf', someStuff)// kill
*/