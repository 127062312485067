import imgSetSrc from './imgSetSrc'
import imgOnload from './imgOnload'

function initLoad(){
  if (sniffer.isSimplified) {
    imgSetSrc($('.toload--simplified, .toload--simple'))
  } else {
    let $items = $('.toload--complexified, .toload--complexe')
    let length = $items.length
    let loaded = 0
    function check(){
      loaded++
      if (loaded = length) {
        imgSetSrc($('.toload--complexified-second, .toload--complexe-second'))
      }
    }
    imgOnload($items, check)
    imgSetSrc($items)
  }
}

export default initLoad