function resizeContainer(){
  window.pageHeight = 0
  st.min = 0
  st.max = 0

  const $pageContainer = $('#js-scroll')
  const $body = $('body')


  function calculate(){
    let newHeight = $pageContainer.outerHeight()
    newHeight = newHeight<wh?wh:newHeight
    st.max = newHeight - wh < 0 ? 0 : newHeight - wh

    if (newHeight != pageHeight) {
      pageHeight = newHeight
      if (sniffer.isSimplified) return
      $body.css({height: pageHeight});
      $body.css({width: ''});
    }
  }

  // loop resize without leak
  /*function leakLoop(){
    calculate()
    setTimeout(function(){
      requestAnimationFrame(leakLoop)
    }, 3000)
  }
  requestAnimationFrame(leakLoop)*/

  bus.on('bus.resize', function(){
    calculate()
    setTimeout(calculate, 500)
  })
  bus.on('bus.resizeContainer', function(){
    calculate()
    setTimeout(calculate, 500)
  })
  calculate()
}

export default resizeContainer