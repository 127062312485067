//import objectFitImages from 'object-fit-images'
//import objectFitVideos from 'object-fit-videos'

// base stuff
import updateSlug from './base/updateSlug'
import updateLinks from './base/updateLinks'
import updateNavActive from './base/updateNavActive'
import imgSetSrc from './base/imgSetSrc'
import imgOnload from './base/imgOnload'
import normalizeRange from './base/normalizeRange'


//
//import matchHeight from 'jquery-match-height'
import throttle from 'lodash/throttle'

// modules
//import parallax from './base/parallax'
//import blink from './modules/blink'
//import initSwiper from './modules/initSwiper'



//import dateInputPolyfill from 'date-input-polyfill'

function initPage(){

  // force resizeContainer
  $('img').on('load error', throttle(function(){
    bus.emit('bus.resizeContainer')
  }, 100))
  $('img').each(function(){
    let src = $(this).attr('src')
    $(this).attr('src', src)
  })


  updateSlug()
  updateNavActive()

  // date input
  //dateInputPolyfill()

  // video/img cover
  //objectFitVideos()
  //objectFitImages()//objectFitImages(false, {watchMQ: true,skipTest: true})// force object fit

  // modules
  //parallax()

  function activateImage($el){
    $el.each(function(){
      const $self = $(this)
      imgOnload($self, function(){
        $self.addClass('js-active')
      })
      imgSetSrc($self)
    })
  }

  $('.fullhead--imgc').each(function(){
    let $img = $(this).find('img')

    function goMobile(){
      $img.attr('data-src', $img.attr('data-src-mobile'))
      //$img.attr('data-srcset', $img.attr('data-srcset-mobile'))
    }
    function goDesktop(){
      $img.attr('data-src', $img.attr('data-src-desktop'))
      $img.attr('data-srcset', $img.attr('data-srcset-desktop'))
    }

    if (ww > 660) {
      goDesktop()
    } else {
      if ($(this).hasClass('fullhead--imgc__hasmobile')) {
        goMobile()
      } else {
        goDesktop()
      }
    }
  })

  activateImage($('.blurimgc img'))



  /*let currentHash = window.location.hash
  $('.acco--item').each(function(){
    const $self = $(this)

    const $trigger = $self.find('.acco--headline')
    const $target = $self.find('.acco--pan')
    const $targetIn = $target.find('.acco--panheight')

    let h = 0
    let active = false

    function calculate(){
      h = $targetIn.outerHeight()
      $target.css('height', active ? h : 0)
    }

    function changeState(){
      active = !active
      $self.toggleClass('-expanded')
      $trigger.toggleClass('-expanded')
      h = $targetIn.outerHeight()
      $target.css('height', active ? h : 0)
      setTimeout(function(){
        bus.emit('bus.resizeContainer')
      }, 600)
    }

    function eventOn(){
      bus.on('bus.resize', calculate)
      bus.on('bus.pageLeave', eventOff)
      $trigger.on('click', changeState)
    }
    function eventOff(){
      bus.off('bus.resize', calculate)
      bus.off('bus.pageLeave', eventOff)
      $trigger.off('click', changeState)
    }
    calculate()
    eventOn()

    if ($self.is(currentHash)) {
      $trigger.trigger('click')
      setTimeout(function(){
        scrollto($self.offset().top, false)
      }, 600)
    }
  })*/




  // local
  if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
    $('[local-href]').each(function(){
      let $self = $(this)
      let url = $self.attr('local-href')
      $self.attr('href', url)
    })
  }

  // search
  /*if (window.currentSlug === 'search') {
    const urlParams = new URLSearchParams(window.location.search)
    const urlParamsSearch = urlParams.get('s')
    $('.search--input').val(urlParamsSearch)
    $('.search--trigger').trigger('click')
    $('.search--input').focus()
  }*/

  // select
  $('select').each(function(){
    let $self = $(this)
    let $p = $self.parent()
    if ($p.is('.selectcont')) return
    $self.wrap('<div class="selectcont"></div>')
  })

  // scrollto hash
  if (window.location.hash) {
    setTimeout(function(){
      let $target = $(window.location.hash)
      if ($target.is('.js-noanchor')) return
      if ($target.is('.acco')) return
      if ($target.length) {
        scrollto($target.offset().top, false)
      }
    }, 200)
  }






  //blink($('.cta, .btn'))
  //blink($('.pp--share a'))
  //blink($('.collist a'))
  //initSwiper()

  //$('.js-matchheight').matchHeight()


  function updateLetter($el, letter, color){
    let txt = letter ? letter : window.curius_letters[Math.floor(Math.random() * window.curius_letters.length)]
    $el.html(txt)
    let clr = letter ? '' : window.curius_colors[Math.floor(Math.random() * window.curius_colors.length)]
    clr = color ? color : clr
    $el.css('color', clr)
  }

  $('[data-c]').each(function(){
    const $module = $(this)
    let ltr = $module.attr('data-c')
    let clr = $module.attr('data-c-color')
    $module.wrapInner('<span/>')

    function b() {
      $module.addClass('js-active')
      let $letter = $('<div/>')
      $letter.appendTo($module)
      updateLetter($letter, ltr, clr)
    }
    if ($module.hasClass('lettre')) {
      if (sniffer.isSimplified) {
        b()
      } else {
        //$module.addClass('js-active')
      }
    } else {
      b()
    }

  })


  function mouseletter(){
    $('.js-mouseletter').each(function(){
      let $self = $(this)
      let $c = $self.find('span.lettre')
      $c.each(function(){
        let $self = $(this)

        let $letter = $('<div/>')
        $letter.appendTo($self)
        updateLetter($letter, 'C')


        // update letter by mouse speed
        let speed = {
          current : 0,
          eased : 0
        }
        let last = new Date().getTime()
        let throttle = 100
        function raf(){
          speed.current = Math.abs(window.mm.speed.x) + Math.abs(window.mm.speed.y)
          speed.eased = utils.lerp(speed.eased, speed.current, 0.2)

          let sp = speed.eased * 20
          let calcThrottleSpeed = -sp * sp * 0.01 + 100

          let now = new Date().getTime()
          if (now > last + calcThrottleSpeed) {
            if (speed.eased >= 0.2) updateLetter($letter)
            last = now
          }
        }

        function eventOn(){
          bus.on('bus.raf', raf)
          bus.on('bus.pageLeave', eventOff)
        }
        function eventOff(){
          bus.off('bus.raf', raf)
          bus.off('bus.pageLeave', eventOff)
        }
        eventOn()
      })
    })
  }
  if (!sniffer.isSimplified) {
    mouseletter()
  }


  /*
  function phathead(){
    $('.phathead').each(function(){
      let $txt = $(this).find('.phathead--title')
      let tl = gsap.timeline()

      tl.staggerFromTo($txt.find('.line'), 1.6, {y:'130%', rotate:'0.001deg'}, {y:'0%', ease:"expo.out", clearProps:'all'}, 0.15, 0.3)
      tl.fromTo($(this).find('.phathead--cta'), 1.6, {y:20, autoAlpha:0}, {y:0, autoAlpha:1, ease:"expo.out", clearProps:'all'}, 1.4)
    })
  }
  phathead()
  */





  function miniacco(){
    $('.miniacco').each(function(){
      const $module      = $(this)
      const $head        = $module.find('.miniacco--head')
      const $panel       = $module.find('.miniacco--panel')
      const $panelheight = $module.find('.miniacco--height')

      let active = false

      function update(){
        active = !active

        $module.toggleClass('js-active', active)

        let h = active ? $panelheight.outerHeight() : 0
        $panel.css('height', h)
        setTimeout(function(){
          bus.emit('bus.resizeContainer')
        }, 1000)
      }

      function eventOn(){
        $head.on('click', update)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $head.off('click', update)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
    })
  }
  miniacco()




  function meth(){
    $('.meth').each(function(){
      const $module = $(this)

      const $video = $module.find('video')

      let canLeave = false

      function hover(e){
        if (e.type === 'mouseenter') {
          if (canLeave){
            enter()
          }
          canLeave = false
        } else {
          canLeave = true
        }
      }

      function enter(){
        $video[0].currentTime = 0
        $video[0].play()
        //gsap.killTweensOf([$video])
        //let tl = gsap.timeline()
        //tl.to($video, .3, {alpha:1, ease:'expo.out'}, 0)
      }
      function leave(){
        //gsap.killTweensOf([$video])
        $video[0].currentTime = 0
        $video[0].pause()
        //let tl = gsap.timeline()
        //tl.to($video, .3, {alpha:0, ease:'expo.out', onComplete:function(){
        //}}, 0)
      }
      function end(){
        if (canLeave) {
          leave()
        } else {
          enter()
        }
      }



      function eventOn(){
        $module.on('mouseenter mouseleave', hover)
        $video.on('ended', end)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $module.off('mouseenter mouseleave', hover)
        $video.off('ended', end)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
    })
  }
  meth()


  function ppitem(){
    $('.ppitem').each(function(){
      const $module = $(this)

      const $video = $module.find('video')

      function hover(e){
        if (e.type === 'mouseenter') {
          enter()
        } else {
          leave()
        }
      }

      let canPlay = 0
      let currentSrc = ''

      function enter(){
        canPlay++
        let width = Math.floor($module.outerWidth())

        let newSrc = $video.attr('data-src-1200')
        //if (width < 1200) newSrc = $video.attr('data-src-1200')
        if (width < 1000) newSrc = $video.attr('data-src-1000')
        if (width < 800)  newSrc = $video.attr('data-src-800')
        if (width < 600)  newSrc = $video.attr('data-src-600')
        if (width < 400)  newSrc = $video.attr('data-src-400')

        if (newSrc !== currentSrc) {
          $video.attr('src', newSrc)
        }
        $video[0].currentTime = 0
        $video[0].play()

      }
      function leave(){
        canPlay--
        gsap.killTweensOf([$video])
        let tl = gsap.timeline()
        tl.to($video, .3, {alpha:0, ease:'expo.out', onComplete:function(){
          $video[0].pause()
        }}, 0)
      }
      function onPlay(){
        if (canPlay) {
          gsap.killTweensOf([$video])
          let tl = gsap.timeline()
          tl.to($video, .3, {alpha:1, ease:'expo.out'}, 0)
        } else {
          //$video[0].pause()
        }
      }



      function eventOn(){
        $module.on('mouseenter mouseleave', hover)
        $video.on('play', onPlay)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $module.off('mouseenter mouseleave', hover)
        $video.off('play', onPlay)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
    })
  }
  ppitem()




  /*function hompushVid(){
    $('.hompush video').each(function(){
      const $video = $(this)

      function onPlay(){
        $video.addClass('js-active')
      }

      function eventOn(){
        $video.on('play', onPlay)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $video.off('play', onPlay)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
    })
  }
  hompushVid()*/
  function hompush(){
    $('.hompush').each(function(){
      const $module = $(this)

      const $video = $module.find('video')

      if (!$video.length) return

      let width = Math.floor($module.outerWidth())
      if (width <= 660) {
        $video.remove()
        return
      }

      let newSrc = $video.attr('data-src-1920') ? $video.attr('data-src-1920') : null
      //if (width < 1920) newSrc = $video.attr('data-src-1920')
      if (width < 1600) newSrc = $video.attr('data-src-1600') ? $video.attr('data-src-1600') : null
      if (width < 1280) newSrc = $video.attr('data-src-1280') ? $video.attr('data-src-1280') : null

      $video.attr('src', newSrc)
      $video[0].currentTime = 0
      $video[0].play()

      function onPlay(){
        $video.addClass('js-active')
      }

      function eventOn(){
        $video.on('play', onPlay)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $video.off('play', onPlay)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
    })
  }
  hompush()


  function fullhead(){
    $('.fullhead').each(function(){
      const $module = $(this)

      const $video = $module.find('video')

      if (!$video.length) return

      let width = Math.floor($module.outerWidth())
      if (width <= 660) {
        $video.remove()
        return
      }

      let newSrc = $video.attr('data-src-1920') ? $video.attr('data-src-1920') : null
      //if (width < 1920) newSrc = $video.attr('data-src-1920')
      if (width < 1600) newSrc = $video.attr('data-src-1600') ? $video.attr('data-src-1600') : null
      if (width < 1280) newSrc = $video.attr('data-src-1280') ? $video.attr('data-src-1280') : null

      $video.attr('src', newSrc)
      $video[0].currentTime = 0
      $video[0].play()

      function onPlay(){
        gsap.to($video, .6, {alpha:1, ease:'expo.out'}, 0)
      }

      function eventOn(){
        $video.on('play', onPlay)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $video.off('play', onPlay)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
    })
  }
  fullhead()








  function filter(){
    $('.filter').each(function(){
      const $module = $(this)

      const $taxoItem = $module.find('.filtertaxo--item')
      const $taxoTargetItem = $module.find('.filtertargettaxo--item')
      const $filterItem = $module.find('.filterlist--item')

      function updateTaxo(){
        let $self = $(this)
        let id = $self.index()
        if ($taxoTargetItem.eq(id).hasClass('js-active')) {
          $taxoTargetItem.removeClass('js-active')
          $taxoItem.removeClass('js-active')
        } else {
          $taxoTargetItem.removeClass('js-active')
          $taxoTargetItem.eq(id).addClass('js-active')
          $taxoItem.removeClass('js-active')
          $self.addClass('js-active')
        }
      }
      function updateFilter(){
        let $self = $(this)
        $self.toggleClass('js-active')

        let $p = $self.parents('.filterlist')
        let activeLength = $p.find('.filterlist--item.js-active').length
        let txt = activeLength > 0 ? `(${activeLength})` : ''
        let $pp = $self.parents('.filtertargettaxo--item')
        $taxoItem.eq($pp.index()).find('.js-num').html(txt)

        let $currentTaxoItem = $taxoItem.eq($pp.index())
        $taxoItem.not($currentTaxoItem).find('.js-num').html('')
        $taxoTargetItem.not($pp).find('.filterlist--item').removeClass('js-active')

        changeContent()
      }


      let currentFilter = {}
      const $gridItems = $('[data-filter-target]')

      function changeContent(){
        let $activeFilterItem = $filterItem.filter('.js-active')
        currentFilter = {}

        $activeFilterItem.each(function(){
          let $self = $(this)
          let whichFilter = $self.attr('data-filter-which')
          let leFilter = $self.attr('data-filter')

          if (!currentFilter['data-filter-'+whichFilter]) {
            currentFilter['data-filter-'+whichFilter] = []
          }

          currentFilter['data-filter-'+whichFilter].push(leFilter)
        })

        let $currentItems
        $gridItems.addClass('js-dnone')
        $gridItems.removeClass('is-revealed')
        window.canReveal = false
        if ($.isEmptyObject(currentFilter)) {
          $gridItems.removeClass('js-dnone')
          $currentItems = $gridItems
        } else {
          Object.entries(currentFilter).forEach(([key, value]) =>{
            for (var i = value.length - 1; i >= 0; i--) {
              $gridItems.filter(`[${key}*="${value[i]}"]`).removeClass('js-dnone')
              //let $self = $gridItems.filter(`[${key}*="${value[i]}"]`)
              //let canshow = parseInt($self.attr('data-canshow'))
              //$self.filter(`[${key}*="${value}"]`).attr('data-canshow', canshow+1)
            }
          })
          $currentItems = $gridItems.filter(`:not(.js-dnone)`)
        }

        $gridItems.removeClass('js-big')
        $gridItems.attr('data-nth', '')
        $currentItems.each(function(i){
          let id = i+1
          let $self = $(this)
          let nth = id%12
          if (nth%12 === 1 || nth%12 === 8) {
            $self.addClass('js-big')
          }
          $self.attr('data-nth', i+1)
        })

        scrollreveal.update()
        bus.emit('bus.resizeContainer')
        window.canReveal = true
      }


      function eventOn(){
        $taxoItem.on('click', updateTaxo)
        $filterItem.on('click', updateFilter)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $taxoItem.off('click', updateTaxo)
        $filterItem.off('click', updateFilter)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
      changeContent()
    })
  }
  filter()


  function postgrid(){
    $('.js-postgrid').each(function(){
      const $postgrid = $(this)
      const $filters = $('.filterpost--cat')

      let currentFilter

      function updateFilter(){
        let $self = $(this)
        if ($self.hasClass('js-active')) return
        currentFilter = $self.attr('data-cat')
        $filters.removeClass('js-active')
        $self.addClass('js-active')


        window.history.pushState(null, null, "?cat="+currentFilter);
        window.oldCat = currentFilter

        updateGrid()
      }
      function checkParams(){
        if (window.oldPostId && window.oldCat) {
          $filters.filter(`[data-cat="${window.oldCat}"]`).trigger('click')
          forceMoreAndScroll()
        } else {
          const urlParams = new URLSearchParams(window.location.search)
          const urlParamsCat = urlParams.get('cat')
          if (urlParamsCat) {
            let $el = $filters.filter(`[data-cat="${urlParamsCat}"]`)
            if ($el.length) {
              $el.trigger('click')
            } else {
              $filters.eq(0).trigger('click')
            }
          } else {
            $filters.eq(0).trigger('click')
          }
        }
      }



      const $posts = $('.js-postgrid-src .postgrid--item')
      const $gridAp = $('.postgrid--grid')
      let $clones

      let clonesLength
      let increment = ww < 900 ? 6 : 9
      let currentInc = 0

      const $moreBtn = $('.btnmore')
      function updateGrid(){
        $moreBtn.removeClass('js-active')
        $gridAp.empty()
        $clones = $posts.clone()
        if (currentFilter !== 'all') {
          $clones = $clones.filter(`[data-category*="${currentFilter}"]`)
        } else {
          $clones.eq(0).addClass('postgrid--item__big')
        }
        $clones.appendTo($gridAp)
        //if (currentFilter !== 'all') $clones.filter('.postgrid--item__big').remove()

        clonesLength = $clones.length

        currentInc = 9
        if (currentFilter === 'all') currentInc = 7
        if (ww < 900) currentInc = 6
        if (clonesLength > currentInc) $moreBtn.addClass('js-active')
        $clones.slice(0, currentInc).removeClass('dnone').attr('scroll-reveal', 'postgrid--item')

        updateSize()

        scrollreveal.update()
        bus.emit('bus.resizeContainer')
      }

      function updateSize(){
        if (currentFilter === 'all') {
          let $imgc = $clones.eq(0).find('.card--imgc')
          let w = $imgc.outerWidth()
          $imgc.css('min-height', ww > 900-1 ? w*0.56 : '')
        }
      }

      function showMore(){
        $clones.slice(currentInc, currentInc+increment).removeClass('dnone').attr('scroll-reveal', 'postgrid--item')
        currentInc = currentInc+increment

        if (currentInc >= clonesLength) $moreBtn.removeClass('js-active')

        scrollreveal.update()
        bus.emit('bus.resizeContainer')
      }


      function forceMoreAndScroll(){
        let $target = $clones.filter(`[data-postid="${window.oldPostId}"]`)
        let i = 0
        if ($target.hasClass('dnone')) {
          while ($target.hasClass('dnone')) {
            if (i > 40) break
            i++
            $moreBtn.trigger('click')
          }
        }
        scrollto($target.offset().top - 50)
      }


      function eventOn(){
        $moreBtn.on('click', showMore)
        $filters.on('click', updateFilter)
        bus.on('bus.popstate', checkParams)
        bus.on('bus.pageLeave', eventOff)
        bus.on('bus.resize', updateSize)
      }
      function eventOff(){
        $moreBtn.off('click', showMore)
        $filters.off('click', updateFilter)
        bus.off('bus.popstate', checkParams)
        bus.off('bus.pageLeave', eventOff)
        bus.off('bus.resize', updateSize)
      }
      eventOn()
      checkParams()
    })
  }
  postgrid()



  function tag(){
    $('.archivefilter').each(function(){
      const $module = $(this)
      const $filters = $module.find('.archivefilter--cat')

      let currentFilter

      function updateFilter(){
        let $self = $(this)
        if ($self.hasClass('js-active')) return
        currentFilter = $self.attr('data-cat')
        $filters.removeClass('js-active')
        $self.addClass('js-active')
        updateGrid()
      }

      const $target = $('[data-archivefilter-target]')
      const $gridItems = $target.find('.basicgrid--item')

      function updateGrid(){
        window.canReveal = false
        $gridItems.removeClass('is-revealed')
        $target.removeClass('js-active')
        $target.filter(`[data-archivefilter-target="${currentFilter}"]`).addClass('js-active')
        scrollreveal.update()
        bus.emit('bus.resizeContainer')
        window.canReveal = true
      }
      function checkParams(){
        const urlParams = new URLSearchParams(window.location.search)
        const urlParamsFrom = urlParams.get('from')
        if (urlParamsFrom) {
          let $el = $filters.filter(`[data-cat="${urlParamsFrom}"]`)
          if ($el.length) {
            $el.trigger('click')
          } else {
            $filters.eq(0).trigger('click')
          }
        } else {
          $filters.eq(0).trigger('click')
        }
      }


      function eventOn(){
        $filters.on('click', updateFilter)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $filters.off('click', updateFilter)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
      checkParams()
    })
  }
  tag()



  function memo(){
    $('.memo').each(function(){
      const $module = $(this)
      const $ss = $module.find('.memo--ss')
      const $items = $ss.find('.memo--item')
      const $prev = $module.find('.memo--prev')
      const $next = $module.find('.memo--next')
      const itemsLength = $items.length

      let currentId = 0

      function prev(){
        updateId(-1)
      }
      function next(){
        updateId(1)
      }
      function updateId(dir){
        currentId += dir
        if (currentId < 0) currentId = itemsLength-1
        if (currentId >= itemsLength) currentId = 0
        updateSs()
      }
      function updateSs(){
        $items.removeClass('js-active')
        $items.eq(currentId).addClass('js-active')
      }

      let h = 0
      function calculate(){
        h = 0
        $items.each(function(){
          h = $(this).outerHeight() > h ? $(this).outerHeight() : h
        })
        $ss.css('height', h)
      }

      function eventOn(){
        $prev.on('click', prev)
        $next.on('click', next)
        bus.on('bus.resize', calculate)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $prev.off('click', prev)
        $next.off('click', next)
        bus.off('bus.resize', calculate)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
      calculate()
      updateSs()
    })
  }
  memo()


  function member(){
    $('.member').each(function(){
      const $module = $(this)
      const $cross = $module.find('.member--cross')
      const $imgc = $module.find('.member--imgc')
      const $acco = $module.find('.member--acco')
      const $accoIn = $acco.find('.member--accoin')

      let active = false
      let activeTimeout

      function toggleAcco(){
        $module.toggleClass('js-active')
        $acco.toggleClass('js-active')
        $cross.toggleClass('js-active')
        active = !active
        if (active) {
          $acco.css('height', $accoIn.outerHeight())
        } else {
          $acco.css('height', '')
        }
        clearTimeout(activeTimeout)
        activeTimeout = setTimeout(function(){
          bus.emit('bus.resizeContainer')
        }, 1000)
      }

      function eventOn(){
        $imgc.on('click', toggleAcco)
        $cross.on('click', toggleAcco)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $imgc.off('click', toggleAcco)
        $cross.off('click', toggleAcco)
        bus.off('bus.pageLeave', eventOff)
      }
      eventOn()
    })
  }
  member()



  /*function btnstr(){
    $('.btnstr').each(function(){
      const $module = $(this)
      const $txtcont = $module.find('.btnstr--text')

      let $txtNormalSpan
      let $txtHoverSpan

      function build(){
        let $el = $txtcont.find('.btnstr--text-normal')
        let characters = $el.text().split("")
        $el.empty()
        $.each(characters, function (i, el) {
          if (el === ' ') {
            $el.append(' ')
          } else {
            $el.append("<span>" + el + "</span")
          }
        })

        let $hover = $el.clone()
        $hover.removeClass('btnstr--text-normal').addClass('btnstr--text-hover')
        gsap.set($hover.find('span'), {y:'120%'})
        $hover.appendTo($txtcont)

        $txtNormalSpan = $txtcont.find('.btnstr--text-normal span')
        $txtHoverSpan = $txtcont.find('.btnstr--text-hover span')
      }


      function hover(e){
        if (e.type === 'mouseenter') {
          enter()
        } else {
          leave()
        }
      }


      function enter() {
        let tl = gsap.timeline()
        tl.to($txtNormalSpan, .6, {y:'-120%', ease:'expo.out', stagger:{amount: 0.1}}, 0)
        tl.to($txtHoverSpan,  .6, {y:'0%',    ease:'expo.out', stagger:{amount: 0.1}}, 0)
      }
      function leave() {
        let tl = gsap.timeline()
        tl.to($txtNormalSpan, .6, {y:'0%',    ease:'expo.out', stagger:{amount: 0.1}}, 0)
        tl.to($txtHoverSpan,  .6, {y:'120%',  ease:'expo.out', stagger:{amount: 0.1}}, 0)
      }


      function eventOn(){
        $module.on('mouseenter mouseleave', hover)
        bus.on('bus.pageLeave', eventOff)
      }
      function eventOff(){
        $module.off('mouseenter mouseleave', hover)
        bus.off('bus.pageLeave', eventOff)
      }
      build()
      eventOn()
    })
  }
  btnstr()*/

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  function animc(){
    $('.animc').each(function(){
      const $module = $(this)
      const $circle = $module.find('.animc--circle')
      const $imgc0 = $module.find('.animc--0')
      const $imgc1 = $module.find('.animc--1')
      const $imgc2 = $module.find('.animc--2')
      const $imgc3 = $module.find('.animc--3')
      const $imgc4 = $module.find('.animc--4')
      const $imgcPara = $module.find('.animc--para')

      const $img0 = $imgc0.find('img')
      const $img1 = $imgc1.find('img')
      const $img2 = $imgc2.find('img')
      const $img3 = $imgc3.find('img')
      const $img4 = $imgc4.find('img')
      let canUpdate = true
      let oldRand = 0
      function update(){
        if (!canUpdate) return
        let duration = getRandomArbitrary(5, 7)

        gsap.to($img0, duration, {
          alpha:getRandomArbitrary(0.8, 1),
          rotate:getRandomArbitrary(-180, 180),
          scale:getRandomArbitrary(0.9, 1.1),
          ease:'power1.inOut'
        }, 0)
        gsap.to($imgcPara, duration, {
          alpha:getRandomArbitrary(0.8, 1),
          rotate:getRandomArbitrary(-180, 180),
          scale:getRandomArbitrary(0.8, 1.2),
          ease:'power1.inOut'
        }, 0)

        setTimeout(update, duration*1000)
      }

      let start = 0
      let end = 0

      function calculate(){
        let size = ww*0.35
        $module.css('width', size)
        $module.css('height', size)
        $circle.css('width', size*0.4)
        $circle.css('height', size*0.4)

        start = 0
        end = $module.offset().top + $module.outerHeight()
      }

      let tl = gsap.timeline()
      tl.pause()
      tl.fromTo($imgcPara, 3.5, {x:'-10%', y:'15%'}, {x:'-30%', y:'-30%', ease:'none'}, 0)

      let tl2 = gsap.timeline()
      tl2.pause()
      tl2.fromTo(tl, 3, {progress:0}, {progress:1, ease:'power1.inOut'}, 0)
      let progress = {current:0, eased:0}

      function raf(){
        progress.current = gsap.utils.clamp(0, 1, normalizeRange(st.eased, start, end, 0, 1))
        let newEased = utils.lerp(progress.eased, progress.current, 0.05)
        if (Math.round(newEased*10000)/10000 !== Math.round(progress.eased*10000)/10000) {
          tl2.progress(newEased)
        }
        progress.eased = newEased
      }
      function eventOn(){
        bus.on('bus.resize', calculate)
        bus.on('bus.raf', raf)
        bus.on('bus.pageLeaveEnd', eventOff)
      }
      function eventOff(){
        canUpdate = false
        bus.off('bus.resize', calculate)
        bus.off('bus.raf', raf)
        bus.off('bus.pageLeaveEnd', eventOff)
      }
      eventOn()
      calculate()
      update()
    })
  }
  animc()


  function singleSticky(){
    $('.post--sticky').each(function(){
      const $module = $(this)
      const $parent = $module.parent()

      let start = 0
      let end = 0

      let l = 0
      let t = 0
      let w = 0

      let decal = 100

      function calculate(){
        start = $parent.offset().top - decal
        end = start + $parent.outerHeight() - $module.outerHeight()

        l = $parent.offset().left
        t = decal
        w = $parent.outerWidth()
      }

      function raf(){
        if (st.eased > start && st.eased < end) {
          $module.addClass('js-sticky')
          $module.removeClass('js-end')
          $module.css('left', l)
          $module.css('top', t)
          $module.css('width', w)
        } else if (st.eased > end) {
          $module.removeClass('js-sticky')
          $module.addClass('js-end')
          $module.css('left', '')
          $module.css('top', '')
          $module.css('width', '')
        } else {
          $module.removeClass('js-sticky')
          $module.removeClass('js-end')
          $module.css('left', '')
          $module.css('top', '')
          $module.css('width', '')
        }
      }
      function eventOn(){
        bus.on('bus.resize', calculate)
        bus.on('bus.raf', raf)
        bus.on('bus.pageLeaveEnd', eventOff)
      }
      function eventOff(){
        bus.off('bus.resize', calculate)
        bus.off('bus.raf', raf)
        bus.off('bus.pageLeaveEnd', eventOff)
      }
      eventOn()
      calculate()
    })
  }
  singleSticky()


  bus.emit('bus.resizeContainer')
}

export default initPage