import busResize from './bus/busResize'
import busScroll from './bus/busScroll'
import busRaf from './bus/busRaf'
import busMouse from './bus/busMouse'
import mouseSpeed from './bus/mouseSpeed'

import ajax from './base/ajax'


import scrollto from './base/scrollto'
import scrollreveal from './base/scrollreveal'


import anim from './anim/anim'

import initPage from './initPage'

// modules
import burger from './modules/burger'
import blink from './modules/blink'
//import matchHeight from 'jquery-match-height'

import resizeContainer from './base/resizeContainer'



function initSite(){
  window.st = {}

  $('body').on('mousedown', 'img', function() { return false; })
  /*$('body').on('click', '.headslider--back', function() {
    window.history.back()
  })*/

  $('body').on('click', '.phathead--scroll', function(){
    let $target = $(this).parents('.phathead').next()
    let targetVal = $target.length ? $target.offset().top : 0
    //smoothScrollTo($tar.offset().top - $('.navbar').outerHeight())
    smoothScrollTo(targetVal)
  })
  $('body').on('click', '.bhead--scroll', function(){
    let $target = $(this).parents('.bhead').next()
    let targetVal = $target.length ? $target.offset().top : 0
    //smoothScrollTo($tar.offset().top - $('.navbar').outerHeight())
    smoothScrollTo(targetVal)
  })
  $('body').on('click', '.minifoot--scroll', function(){
    smoothScrollTo(0)
  })



  if (sniffer.isSimplified) $('html').addClass('js-simplified')
  if (!sniffer.isSimplified) $('html').addClass('js-complexified')

  // bus
  busResize()
  busRaf()
  busScroll()
  busMouse()
  mouseSpeed()

  resizeContainer()
  window.smoothScrollTo = window.smoothScrollto = window.smoothscrollto = window.scrollto = scrollto// smoothscrollto(300)


  // reveal
  window.canReveal = false
  window.scrollreveal = scrollreveal
  scrollreveal.update()
  scrollreveal.eventsOn()

  // base
  ajax()


  window.anim = anim

  // init modules once
  burger()
  blink($('.foot--nav a'))



  /*$('html').on('wpcf7mailsent', function(){
    //$('.plusun-1, .plusun-2').removeClass('js-active')
  })*/



  function cssVarsSize(){
    const $navbar = $('.navbar')
    const $foot = $('.foot')
    function setSize(){
      document.documentElement.style.setProperty('--vh', `${wh}px`)
      document.documentElement.style.setProperty('--vw', `${ww}px`)
      document.documentElement.style.setProperty('--navheight', `${$navbar.outerHeight()}px`)
      document.documentElement.style.setProperty('--footheight', `${$foot.outerHeight()}px`)
    }
    bus.on('bus.resize', setSize)
    setSize()
  }
  cssVarsSize()


  function debug(){
    if (!window.modeDebug) return

    let $div = $('<div/>')

    let snif = {}
    snif.scrollType = 'scroll : ' + window.scrollType
    snif.mode = sniffer.isSimplified ? 'mode : simple' : 'mode : complex'
    snif.isIOS = sniffer.isIOS ? 'isIOS : yes' : 'isIOS : no'
    snif.isAndroid = sniffer.isAndroid ? 'isAndroid : yes' : 'isAndroid : no'
    snif.isTouch = sniffer.isTouch ? 'isTouch : yes' : 'isTouch : no'
    snif.isSmallDevice = sniffer.isSmallDevice ? 'isSmallDevice : yes' : 'isSmallDevice : no'
    snif.isMac = sniffer.isMac ? 'isMac : yes' : 'isMac : no'
    snif.isSafari = sniffer.isSafari ? 'isSafari : yes' : 'isSafari : no'
    snif.isMobile = sniffer.isMobile ? 'isMobile : yes' : 'isMobile : no'
    snif.isIE = sniffer.isIE ? 'isIE : yes' : 'isIE : no'

    $div.html(
      `${snif.scrollType}
      <br>${snif.mode}
      <br>${snif.isIOS}
      <br>${snif.isAndroid}
      <br>${snif.isTouch}
      <br>${snif.isSmallDevice}
      <br>${snif.isMac}
      <br>${snif.isSafari}
      <br>${snif.isMobile}
      <br>${snif.isIE}`
    )
    $div.addClass('sniffer').appendTo($('html'))
    $div.on('click', function(){
      $(this).toggleClass('js-inactive')
    })
  }
  debug()




  function hasScrolled(){
    let $html = $('html')

    function raf(){
      $html.toggleClass('js-scrolled', st.current > 100)
    }

    function eventOn(){
      bus.on('bus.raf', raf)
    }
    raf()
    eventOn()
  }
  hasScrolled()


  // page
  initPage()

}

export default initSite