let scrollreveal = {

  cacheItems : [],
  cacheItemsLength : 0,

  update () {
    this.cacheItems = $('[scroll-reveal]')
    this.cacheItemsLength = this.cacheItems.length

    let smallerOffset = window.ww < 660 ? true : false

    for (let i = 0; i <= this.cacheItemsLength-1; i++) {
      const item = this.cacheItems[i]
      item.top = Math.round($(item).offset().top)
      //if (!sniffer.isSimplified) item.top += Math.round(st.eased)
      if (!sniffer.isSimplified && window.scrollType === 'virtual') item.top += Math.round(st.eased)
      item.offset = $(item).attr('reveal-offset') ? Math.abs(parseInt($(item).attr('reveal-offset')))*-1 : 0
      item.height = $(item).outerHeight()

      item.decal = smallerOffset ? item.offset/2 : item.offset
      item.start = Math.round(item.top - item.decal - wh)
      item.end = Math.round(item.top + item.height)

      // debug purpose
      //$(item).attr('data-top', item.top)
      //$(item).attr('data-start', item.start)
      //$(item).attr('data-end', item.end)
    }
  },

  seekReveal () {
    if (!window.canReveal) return

    for (let i = 0; i <= scrollreveal.cacheItemsLength-1; i++) {
      const item = scrollreveal.cacheItems[i]
      const $item = $(item)
      if ($item.hasClass('dnone')) return
      if (!$item.hasClass('is-revealed')) {
        if (st.eased > item.start && st.eased < item.end) {
          $item.addClass('is-revealed')
          //$item.trigger('reveal')
          anim.scrollReveal($item)
        }
      }
    }
  },

  eventsOn (){
    bus.on('bus.resize',          function(){setTimeout(function(){scrollreveal.update()}, 50)})// delay = prevent bug from scroll
    bus.on('bus.resizeContainer', function(){setTimeout(function(){scrollreveal.update()}, 50)})// delay = prevent bug from scroll
    bus.on('bus.raf',             function(){scrollreveal.seekReveal()})
  }
}

export default scrollreveal