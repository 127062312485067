function scrollto(to, instant){
  if (sniffer.isSimplified || window.scrollType === 'default') {
    if (instant) {
      $(window).scrollTop(to)
    } else {
      let scrollVal = {current:st.current, from:st.current, to:gsap.utils.clamp(st.min, st.max, to)}
      function updatePos(){
        $(window).scrollTop(scrollVal.current)
      }
      gsap.fromTo(scrollVal, 1, {current: scrollVal.from}, {current: scrollVal.to, ease:"expo.inOut", onUpdate:updatePos})
    }
  } else if (window.scrollType === 'virtual') {// virtual scroll
    if (instant) {
      st.current = to
      st.eased = to
    } else {
      let scrollVal = {current:st.eased, from:st.eased, to:gsap.utils.clamp(st.min, st.max, to)}
      function updatePos(){
        st.current = st.eased = scrollVal.current
      }
      gsap.fromTo(scrollVal, 1, {current: scrollVal.from}, {current: scrollVal.to, ease:"expo.inOut", onUpdate:updatePos})
    }
  } else {// smooth scroll
    if (instant) {
      $(window).scrollTop(to)
      st.current = st.eased = to
    } else {
      $(window).scrollTop(to)
    }
  }
}

export default scrollto