function blink($el) {
  $el.each(function(){
    let $module = $(this)
    $module.addClass('blink')
    let text = $module.html()
    $module.empty()
    let $normal = $('<div/>')
    $normal.addClass('blink--normal').html(text)
    $normal.appendTo($module)
    let $hover = $('<div/>')
    $hover.addClass('blink--hover').html(text)
    $hover.appendTo($module)

    $module.wrapInner('<div class="blink--padder"></div>')
  })
}

export default blink