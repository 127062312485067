function imgOnload($el, fn) {
  let loadedOnce = false

  function launch() {
    if (typeof $el.attr('src') === 'undefined') return
    if (!loadedOnce) {
      loadedOnce = true
      fn()
    }
  }

  $el.on('load error', e => {
    launch()
  }).each(function(){
    if (this.complete || this.complete === undefined){
      launch()
    }
  })
}
export default imgOnload