import debounce from 'lodash/debounce';

function busResize() {
  onResize()
  $(window).on('resize', debounce(onResize, 100))

  function onResize() {
    window.ww = window.winWidth = window.wWidth = window.winwidth = window.wwidth = document.documentElement.clientWidth
    window.wh = window.winHeight = window.wHeight = window.winheight = window.wheight = document.documentElement.clientHeight
    bus.emit('bus.resize')
  }
}

export default busResize