function pageTransiEnter() {
  bus.emit('bus.pageEnter')

  window.preventScrollTransi = false

  // timeline
  let tl = gsap.timeline()

  tl.to($('.pagecont:first'), 1, {alpha:1, ease:"power3.out"}, 0)

  let $el = $('.pagecont:first > *:not(:first-child):not([reveal-hidden])')
  tl.fromTo($el, 1, {alpha:0, y:20}, {alpha:1, y:0, ease:"power3.out"}, 0.5)
  tl.to($('.foot'), 1, {alpha:1, ease:"power3.out"}, 0.5)


  // bands
  if (typeof window.firstLoad !== 'undefined') {
    gsap.killTweensOf($('.pageload'))
    tl.to($('.pageload'), .6, {x:'0%', ease:'expo.inOut'}, 0)
    tl.to($('.pageload'), 1, {y:'-101%', ease:'power2.inOut'}, 1)
  }
  window.firstLoad = false



  scrollreveal.update()
  window.canReveal = true

}

module.exports = pageTransiEnter