// libs
import gsap from "gsap"
window.gsap = gsap

import jQuery from "jquery"
window.$ = window.jQuery = jQuery


// app
import * as utils from "./base/utils"
import initSite from "./initSite"
import preloader from "./anim/preloader"
import sniffer from './base/sniffer'
import emitter from './bus/emitter'


$(function() {

  window.scrollType = 'default'// virtual || smooth || default
  window.modeDebug = false

  //window.virtualScroll = false
  //window.smoothScroll = false
  //window.forceSimplified = false

  //window.preloader = "fast"
  //window.preloader = false
  if (window.location.host === 'localhost:3000') window.preloader = false
  if (window.location.host === '192.168.1.53:3000') window.preloader = false


  gsap.config({nullTargetWarn: false})
  gsap.ticker.fps(240)

  window.sniffer = sniffer
  sniffer.init()
  window.utils = utils
  window.bus = new emitter()



 window.curius_letters = [
    "&#57344;",
    "&#57345;",
    "&#57360;",
    "&#57361;",
    "&#57362;",
    "&#57363;",
    //"&#57364;",
    "&#57365;",
    "&#57366;",
    "&#57367;",
    "&#57368;",
    "&#57369;",
    "&#57346;",
    "&#57376;",
    "&#57377;",
    "&#57378;",
    "&#57379;",
    "&#57380;",
    "&#57381;",
    "&#57382;",
    "&#57383;",
    "&#57384;",
    "&#57385;",
    "&#57347;",
    "&#57392;",
    "&#57393;",
    "&#57394;",
    "&#57395;",
    "&#57396;",
    "&#57397;",
    "&#57398;",
    "&#57399;",
    //"&#57400;",
    //"&#57401;",
    "&#57348;",
    //"&#57408;",
    "&#57409;",
    "&#57410;",
    "&#57411;",
    "&#57412;",
    "&#57413;",
    "&#57414;",
    "&#57415;",
    "&#57416;",
    "&#57417;",
    "&#57349;",
    "&#57424;",
    "&#57425;",
    "&#57426;",
    "&#57427;",
    "&#57428;",
    "&#57429;",
    "&#57430;",
    "&#57431;",
    "&#57432;",
    "&#57433;",
    "&#57350;",
    "&#57440;",
    "&#57441;",
    "&#57351;",
    "&#57352;",
    "&#57353;"
  ]
  window.curius_colors = [
    "#ff317b",
    "#ff81ff",
    "#13ffbb",
    "#ffe751",
    "#5b00f8",
    "#21d7f6",
    "#d6d0d0",
  ]

  preloader()
  initSite()
});