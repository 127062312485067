const sniffer = {
    userAgent: navigator.userAgent.toLowerCase(),
    isSimplified : false,

    get isMobileIE () {
        return /iemobile/i.test(this.userAgent)
    },

    get isMobileOpera () {
        return /opera mini/i.test(this.userAgent)
    },

    get isIOS () {
        return /iphone|ipad|ipod/i.test(this.userAgent)
    },

    get isBlackberry () {
        return /blackberry/i.test(this.userAgent)
    },

    get isMobileAndroid () {
        return /android.*mobile/.test(this.userAgent)
    },

    get isAndroid () {
        return this.isMobileAndroid || !this.isMobileAndroid && /android/i.test(this.userAgent)
    },

    get isFirefox () {
        return this.userAgent.indexOf('firefox') > -1
    },

    get safari () {
        return this.userAgent.match(/version\/[\d\.]+.*safari/)
    },

    get isSafari () {
        return !!this.safari && !this.isAndroid
    },

    get isSafariOlderThan8 () {
        let limit = 8
        let version = limit
        if (this.isSafari) {
            let versionWithVersionWord = this.safari[0].match(/version\/\d{1,2}/)
            version = +versionWithVersionWord[0].split('/')[1]
        }
        return version < limit
    },

    get isIEolderThan11 () {
        return this.userAgent.indexOf('msie') > -1
    },

    get isIE11 () {
        return navigator.appVersion.indexOf('Trident/') > 0
    },

    get isIE () {
        return this.isIEolderThan11 || this.isIE11
    },

    get isEdge () {
        return /Edge\/\d./i.test(this.userAgent)
    },

    get isMac () {
        return navigator.platform.toLowerCase().indexOf('mac') > -1
    },

    get isMobile () {
        return this.isMobileAndroid || this.isBlackberry || this.isIOS || this.isMobileOpera || this.isMobileIE
    },

    get isTouch () {
        return 'ontouchstart' in window
    },

    get isSmallDevice() {
        return window.innerWidth < 1024
    },

    init() {
        if (this.isIE || this.isMobile || this.isSmallDevice) this.isSimplified = true
        if (window.forceSimplified) this.isSimplified = true
    }
}

export default sniffer