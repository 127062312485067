import splitting from "splitting"

var wordwrap = function($el){
  this.isReverted = false
  this.revert = function() {
    if (!$el.length) return
    if (this.isReverted) return
    $target.replaceWith($clone)
    this.isReverted = true
  };

  if (!$el.length) return
  let $target = $el
  let $clone = $target.clone()
  let html = $target.html()
  let splitter = splitting({target:$target, by:'lines'})

  for (let i = 0; i <= splitter[0].words.length-1; i++) {
    $(splitter[0].words[i]).addClass('oh vab').wrapInner('<div class="word--in"/>')
  }
  let $wordIn = $target.find('.word--in')

};

export default wordwrap