import pageTransiEnter from './pageTransiEnter'

function preloader() {

  let duration = 1.5

  let $preloader = $('.preloader')

  let preloadTl = gsap.timeline()
  preloadTl.pause()




  function updateLetter($el, letter){
    let txt = letter ? letter : window.curius_letters[Math.floor(Math.random() * window.curius_letters.length)]
    $el.html(txt)
    let clr = letter ? '' : window.curius_colors[Math.floor(Math.random() * window.curius_colors.length)]
    $el.css('color', clr)
  }

  let $letter = $('.preloader--letter')

  let canRaf = true

  function thisRaf(){
    if (!canRaf) return
    updateLetter($letter)
    setTimeout(function(){
      requestAnimationFrame(thisRaf)
    }, 1000/16)
  }
  requestAnimationFrame(thisRaf)


  preloadTl.to($letter, 1, {alpha:1, ease:'expo.inOut'}, 0)
  preloadTl.to($letter, 1, {alpha:0, ease:'expo.inOut'}, duration)
  preloadTl.to($('.preloader--bg'), 1, {alpha:0, ease:'expo.inOut'}, duration + 0.5)

  preloadTl.add(function(){
    pageTransiEnter()
  }, duration + 1.2)


  preloadTl.staggerFromTo($('.navbar--logo path'), 1, {attr:{style:'transform:translateY(110%)'}}, {attr:{style:'transform:translateY(0%)'}, ease:'expo.out'}, 0.15, duration + 1.2)
  preloadTl.staggerFromTo($('.navbar--burger > div'), 1, {scaleX:0}, {scaleX:1, ease:'expo.out'}, 0.15, duration + 1.2)



  preloadTl.call(function(){
    $('html').addClass('js-siteloaded')

    setTimeout(function(){
      gsap.set($('.navbar--logo path'), {clearProps:'all'})
      gsap.set($('.navbar--burger > div'), {clearProps:'all'})
    }, 50)

    canRaf = false
    $preloader.remove()
  })



  if (window.preloader === "fast") {
    gsap.to(preloadTl, 3, {progress:1})
  } else if (window.preloader === false) {
    gsap.to(preloadTl, .1, {progress:1})
  } else {
    preloadTl.play()
  }


}

export default preloader