function imgSetSrc($el) {
  $el.each(function(){
    let $self = $(this)

    if (!$self.attr('src') && $self.attr('data-src')) {
      let dataSrc = $self.attr('data-src')
      $self.attr('src', dataSrc)
      $self.removeAttr('data-src')
    }
    if (!$self.attr('srcset') && $self.attr('data-srcset')) {
      let dataSrcset = $self.attr('data-srcset')
      $self.attr('srcset', dataSrcset)
      $self.removeAttr('data-srcset')
    }

  })
}

export default imgSetSrc