// normalize any number between ranges
function normalizeRange (inputVal, inputMin, inputMax, outputMin, outputMax)  {
  const inputRange = inputMax - inputMin;
  const outputRange = outputMax - outputMin;
  return (((inputVal - inputMin) * outputRange) / inputRange) + outputMin;
};

export default normalizeRange;

/*
normalizeRange(0,    0, 1, -100, 100) // -100
normalizeRange(0.25, 0, 1, -100, 100) // -50
normalizeRange(0.5,  0, 1, -100, 100) // 0
normalizeRange(0.75, 0, 1, -100, 100) // 50
normalizeRange(1,    0, 1, -100, 100) // 100
*/