function busMouse() {

  window.mm = {
    isClick : false,
    down : false,
    posStart : {
      date: null,
      x: 0,
      y: 0
    },
    posEnd : {
      date: null,
      x: 0,
      y: 0
    },
    posCurrent : {
      x: window.ww/2,
      y: window.wh/2
    },
    posDrag : {
      x: 0,
      y: 0
    },
    targetMouseClick : null,
    targetMouseMove : null,

    speed : {
      x: 0,
      y: 0,
      xy: 0,
    }
    /*speed : {
      oldDate: null,
      oldX: 0,
      oldY: 0,
      newDate: null,
      newX: 0,
      newY: 0,
      x: 0,
      y: 0,
      xy: 0,
    }*/
  }

  function onStart(e) {
    mm.down = true
    mm.posStart.x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
    mm.posStart.y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;
    mm.posStart.date = new Date()

    mm.targetMouseClick = e.target

    bus.emit("bus.cursorStart")
  }
  function onEnd(e) {
    mm.down = false

    mm.posEnd.x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
    mm.posEnd.y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;
    mm.posEnd.date = new Date()

    mm.isClick = Math.abs(mm.posDrag.x) < 10 && Math.abs(mm.posDrag.y) < 10 && window.mm.posEnd.date - window.mm.posStart.date < 300 ? true : false
    if (mm.isClick) {
      bus.emit("bus.click", mm.targetMouseClick)
    }

    bus.emit("bus.cursorEnd")
  }
  function onMove(e) {
    mm.posCurrent.x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
    mm.posCurrent.y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

    mm.targetMouseMove = e.target

    mm.posDrag.x = mm.down ? mm.posStart.x - mm.posCurrent.x : 0
    mm.posDrag.y = mm.down ? mm.posStart.y - mm.posCurrent.y : 0

    bus.emit("bus.cursorMove")
  }

  window.addEventListener('touchstart', onStart, {passive: true})
  window.addEventListener('mousedown', onStart, {passive: true})
  //$(window).on('touchstart mousedown', onStart)

  window.addEventListener('touchend', onEnd, {passive: true})
  window.addEventListener('mouseup', onEnd, {passive: true})
  //$(window).on('touchend mouseup', onEnd)

  window.addEventListener('touchmove', onMove, {passive: true})
  window.addEventListener('mousemove', onMove, {passive: true})
  //$(window).on('mousemove touchmove', onMove)

}

export default busMouse