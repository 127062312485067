import pageTransiEnter from '../anim/pageTransiEnter'
import pageTransiLeave from '../anim/pageTransiLeave'

import initPage from '../initPage'
import imgLoad from './imgLoad'


function ajax() {
  //console.log('ajax')

  // load image
  imgLoad()

  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual'
  }

  let pagesData = []
  window.preventPageChanging = false
  window.pageTransiType = false
  window.$linkTransi = false
  let currentPathname = document.location.pathname

  // History stage changed
  function historyStateChanged(e) {
    bus.emit('bus.popstate')
    if (document.location.pathname == currentPathname) return
    currentPathname = document.location.pathname

    pageTransiType = false
    $linkTransi = false
    requestPage(window.location.href.trimHttp().trimSlash(), false)
  }

  // Save pages data for further use
  function savePagesData(url, data) {
    url = url.trimHttp().trimSlash().replace(/\/$/, '')
    pagesData[url] = data;
  }

  // Get pages data
  function getPagesData(url) {
    url = url.trimHttp().trimSlash().replace(/\/$/, '')
    return pagesData[url]
  }

  // link handler
  function linkHandler(e) {
    const $link = $(this)

    window.oldPostId = $(this).attr('data-postid') || null

    if ($link.is('[hreflang]')) return
    if ($link.is('[target="_blank"]')) return

    let linkHref = $link.attr('href')

    // trim http/slash + replace by localhost
    if (linkHref !== undefined) {
      //linkHref = linkHref.trimHttp().trimSlash()
      const hostname = location.hostname
      const portdot = location.port ? ':'+location.port : ''
      linkHref = linkHref.replace('127.0.0.1', hostname+portdot)
    }

    // if no-link = do nothing
    if (linkHref === undefined || $link.hasClass('no-link')) {
      //console.log('NOPE')
      return
    }

    // href="#"
    if (linkHref.charAt(0) === '#') {
      e.preventDefault()
      if (linkHref.length === 1) return
      const $target = $(linkHref)
      if ($target.length) {
        let pos = $target.offset().top
        if (!sniffer.isSimplified) pos += st.eased
        scrollto(pos)
      }
      return
    }

    // if link HREF is current page, do nothing
    //if (document.URL.trimSlash().trimHttp() === linkHref.trimSlash().trimHttp()) {
      ////console.log('same URL, do nothing')
      //e.preventDefault()
      //$(window).trigger('trigger-navleave')
      //return
    //}

    //console.log(linkHref)

    // Check if it's an internal URL
    if (linkHref.trimSlash().trimHttp().indexOf(location.hostname) === 0) {
      e.preventDefault()
      if (window.preventPageChanging) return

      pageTransiType = $link.attr('page-transi-type') || false
      $linkTransi = $link

      // Load page
      requestPage(linkHref.trimHttp().trimSlash(), true)
    } else {
      e.preventDefault();
      window.open(linkHref, '_blank');
      return
    }
  }

  // page loading
  function requestPage(url, push) {
    window.canReveal = false

    window.preventPageChanging = true

    //scrollto(0, true)
    pageTransiLeave()
    bus.emit('bus.navleave')

    //return// debug mode

    url.trimHttp().trimSlash()
    //console.log(url)

    // Check if data exists
    const data = getPagesData(url)
    //console.log('data : '+data)

    if (data == undefined) {
      //console.log('request : '+url)
      $.get(location.protocol + '//' + url, function (data) {
        savePagesData(url, data)
        pageCreate(data)
      }).fail(function () {
        window.location = location.protocol + '//' + url
      })
    } else {
      //console.log('already has : '+url)
      pageCreate(data)
    }

    if (push) {
      history.pushState(null, null, location.protocol + '//' + url)
    }

    currentPathname = document.location.pathname
  }






  // Create new page
  function pageCreate(data) {

    // Convert data to HTML
    const dataHtml = $('<div/>').html(data)

    // Create new container
    const $oldContainer = $('.pagecont')
    const $newContainer = dataHtml.find('.pagecont')
    $newContainer.prependTo($('.ajaxcont'))

    // load images (complex | simplified | mobile | tablet | laptop | desktop)
    imgLoad()

    // hide new container (and prevent new one to be pushed)
    gsap.set($newContainer, { alpha: 0, position: 'absolute', top: 0, left: 0, width: '100%', x:'-9000%' })

    function updatePage() {
      //console.log('try updatePage, status of prevent : ' + window.preventPageChanging)
      if (window.preventPageChanging) {
        requestAnimationFrame(updatePage)
        return
      }

      // Switch metas
      //$('head meta').filter('[name="description"], [name="keywords"], [property="og:image"]').remove()
      //dataHtml.find('meta').filter('[name="description"], [name="keywords"], [property="og:image"]').insertAfter('head meta[name="viewport"]')

      // Set title
      $('head title').html(dataHtml.find('title').html())

      // Switch nav/foot
      $('.wp-foot').replaceWith(dataHtml.find('.wp-foot'))
      $('.sideext--lang').replaceWith(dataHtml.find('.sideext--lang'))

      // analytics
      if (typeof ga !== 'undefined') {
        ga('set', {page: window.location.pathname,title: dataHtml.find('title').html()})
        ga('send', 'pageview')
      }
      if (typeof gtag !== 'undefined') {
        gtag('config', 'G-KSCHT8B020', {'page_path': window.location.pathname});
      }

      // Switch
      gsap.set($newContainer, { clearProps:'all' })
      $oldContainer.remove()

      initPage()
      pageTransiEnter()
    }

    updatePage()
  }


  function prehtml(e){
    const $link = $(this)

    let linkHref = $link.attr('href')

    if ($link.hasClass('js-preload') || $link.hasClass('no-link') || $link.is('[target="_blank"]') || linkHref === undefined || linkHref.charAt(0) === '#') return

    linkHref = linkHref.trimHttp().trimSlash()
    const hostname = location.hostname
    const portdot = location.port ? ':'+location.port : ''
    linkHref = linkHref.replace('127.0.0.1', hostname+portdot)

    //if (document.URL.trimSlash().trimHttp() !== linkHref) return// same URL

    if (linkHref.indexOf(hostname) !== 0) return

    // prevent spam request
    $link.addClass('js-preload')
    // request page
    const data = getPagesData(linkHref)

    if (data == undefined) {
      $.get(location.protocol + '//' + linkHref, function (data) {
        savePagesData(linkHref, data)
      })
    }
  }




  /**
   * init
  **/
  $('html').on('click', 'a', linkHandler)
  $('html').on('mouseenter', 'a', prehtml)
  $(window).bind('popstate', historyStateChanged)

}

export default ajax