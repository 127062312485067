function removeFromArray(array, element) {
  const index = array.indexOf(element)
  array.splice(index, 1)
}

function getCoverValue(mediaWidth, mediaHeight, containerWidth, containerHeight) {
  let width = 0
  let height = 0
  let top = 0
  let left = 0
  if (containerHeight / containerWidth > mediaHeight / mediaWidth) {
    width = containerHeight * (mediaWidth / mediaHeight)
    height = containerHeight
  } else {
    width = containerWidth
    height = containerWidth * (mediaHeight / mediaWidth)
  }
  top = (containerHeight - height) / 2
  left = (containerWidth - width) / 2
  return {
    width : width,
    height: height,
    top: top,
    left: left,
  }
}

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function normalizeRange (value, originalMin, originalMax, newMin, newMax)  {
  const originalRange = originalMax - originalMin;
  const newRange = newMax - newMin;
  return (((value - originalMin) * newRange) / originalRange) + newMin;
};

function lerp (start, end, amt){
  return (1-amt)*start+amt*end
}


Number.prototype.roundTo = function(num) {
    var resto = this%num;
    if (resto <= (num/2)) {
        return this-resto;
    } else {
        return this+num-resto;
    }
}

// _____________________________ startsWith polyfill
// _______________________________________________________
if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function(search, pos) {
      pos = !pos || pos < 0 ? 0 : +pos;
      return this.substring(pos, pos + search.length) === search;
    }
  });
}

// _____________________________ real tap
// _______________________________________________________
  function onRealTap($el, callback) {
    let tapTime;
    let moving = false;
    let move = 0;
    let moveOrigin = 0;
    $el.on('touchstart mousedown touchend mouseup touchmove mousemove', function (e) {
      let $self = $(this);

      if (e.type === 'touchstart' || e.type === 'mousedown') {
        moving = true;
        tapTime = Date.now();
        moveOrigin = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
        move = 0;
      }

      if (e.type === 'touchend' || e.type === 'mouseup') {
        moving = false;
        let endTapTime = Date.now();

        if (endTapTime < tapTime + 200 && Math.abs(move) < 10) {
          if (callback) {
            callback($self);
          }
        }
      }

      if (e.type === 'touchmove' || e.type === 'mousemove') {
        if (moving) {
          let thisX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
          move = thisX - moveOrigin;
        }
      }
    });
  };

// _____________________________ trimSlash
// _______________________________________________________
String.prototype.trimSlash = function () {
  return this.replace(/^\/+|\/+$/gm, '')
}

// _____________________________ trimHttp
// _______________________________________________________
String.prototype.trimHttp = function () {
  let text = this.replace(/(^\w+:|^)\/\//, '')// remove https:// http:// and //
  return text
}

// _____________________________ getTranslateY
// _______________________________________________________
function getTranslateY(element) {
  let style = window.getComputedStyle(element.get(0));
  let matrix = style.getPropertyValue('-webkit-transform') || style.getPropertyValue('-moz-transform') || style.getPropertyValue('-ms-transform') || style.getPropertyValue('-o-transform') || style.getPropertyValue('transform');

  if (matrix === 'none') {
    matrix = 'matrix(0,0,0,0,0)';
  }

  let values = matrix.match(/([-+]?[\d\.]+)/g);
  return values[14] || values[5] || 0;
}

// _____________________________ getTranslateX
// _______________________________________________________
function getTranslateX(element) {
  let style = window.getComputedStyle(element.get(0));
  let matrix = style.getPropertyValue('-webkit-transform') || style.getPropertyValue('-moz-transform') || style.getPropertyValue('-ms-transform') || style.getPropertyValue('-o-transform') || style.getPropertyValue('transform');

  if (matrix === 'none') {
    matrix = 'matrix(0,0,0,0,0)';
  }

  let values = matrix.match(/([-+]?[\d\.]+)/g);
  return values[14] || values[4] || 0;
}

// _____________________________ closest element from array
// _______________________________________________________
function closest(x, arr) {
  let indexArr = arr.map(function (k) {
    return Math.abs(k - x);
  });
  let min = Math.min.apply(Math, indexArr);
  return arr[indexArr.indexOf(min)];
};

// _____________________________ stillExists
// _______________________________________________________
function stillExists($elem){
  return $elem.closest("html").length > 0;
}


module.exports = {closest, stillExists, getTranslateY, getTranslateX, onRealTap, lerp, normalizeRange, shuffle, removeFromArray, getCoverValue}