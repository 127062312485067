function mouseSpeed(){

  let leTimestamp = null
  let lastX
  let currentX
  let speedX = 0

  let lastY
  let currentY
  let speedY = 0

  let speedXY = 0

  function init(){
    eventsOn()
  }

  function raf() {
    currentX = window.mm.posCurrent.x
    currentY = window.mm.posCurrent.y

    // get inertia
    if (leTimestamp === null) {
      leTimestamp = Date.now();
      lastX = currentX;
      lastY = currentY;
      return;
    }
    let now = Date.now();
    let dt =  now - leTimestamp;

    // x
    let dX = currentX - lastX;
    speedX = dt === 0 ? 0 : dX / dt;
    lastX = currentX;

    // y
    let dY = currentY - lastY;
    speedY = dt === 0 ? 0 : dY / dt;
    lastY = currentY;

    leTimestamp = now;

    speedXY = speedX*speedY

    // update values
    window.mm.speed.x = speedX
    window.mm.speed.y = speedY
    window.mm.speed.xy = speedXY
  }

  function eventsOn(){
    /*bus.on('bus.cursorStart', cursorStart)
    bus.on('bus.cursorEnd', cursorEnd)
    bus.on('bus.cursorMove', cursorMove)*/

    bus.on('bus.raf', raf)
    //bus.on('bus.pageLeave', eventsOff)
  }
  /*function eventsOff(){
    bus.off('bus.cursorStart', cursorStart)
    bus.off('bus.cursorEnd', cursorEnd)
    bus.off('bus.cursorMove', cursorMove)

    bus.off('bus.raf', raf)
    bus.off('bus.pageLeave', eventsOff)
  }*/

  init()

}
export default mouseSpeed