function burger() {

  // elements
  const $burger = $('.navbar--burger')
  //const $cross = $('.cross')


  /*
  ** ==================== controller
  */

  let deployed = false;
  function updateNav(){
    deployed = !deployed
    $('html').toggleClass('nav-deployed', deployed)

    if (deployed) {
      navShow()
    } else {
      navHide()
    }
  }

  function forceNavLeave(){
    if (deployed) {
      deployed = false
      $('html').removeClass('nav-deployed')
      //window.preventScrollNav = false
      navHide()
    }
  }



  /*
  ** ==================== animation
  */

  let $links = $('.navdown--menu .menu-item a')

  // build <a>
  $links.each(function(){
    let $self = $(this)
    $self.wrapInner('<span/>')
  })
  // default position <a>
  gsap.set($links, {y:'110%'})



  function navShow(){
    gsap.killTweensOf([$links])

    //window.preventScrollNav = true
    let tl = gsap.timeline()

    tl.staggerTo($links, 1, {y:'0%', ease:'power4.out'}, 0.075, 0.5)
  }
  function navHide(){
    gsap.killTweensOf([$links])

    //window.preventScrollNav = false
    let tl = gsap.timeline()

    tl.staggerTo($links, 1, {y:'110%', ease:'power4.out'}, -0.075, 0)
    setTimeout(function(){
      lock = false
    }, 1000)
  }




  /*
  ** ==================== hover item
  */

  let $bigletterCont = $('.navdown--bigletter')

  // build letters
  $links.each(function(){
    let $self = $(this)
    let letter = $self.attr('data-letter')
    let letterColor = $self.attr('data-letter-color')
    let $letter = $('<div/>')
    $letter.html(letter)
    gsap.set($letter, {color:letterColor})
    $letter.appendTo($bigletterCont)
  })

  let $letter = $bigletterCont.find('div')

  let currentIndex = 0
  let itemHover = 0
  let lock = false

  if (!sniffer.isSimplified) {
    $('.navdown--menu').on('mouseenter mouseleave click', 'a', function(e){
      let $self = $(this)

      if (e.type === 'mouseenter') {
        currentIndex = $self.parent().index()+1
        itemHover++
      } else if (e.type === 'click'){
        lock = true
      } else {
        itemHover--
      }

      updateHover()
    })
  }

  function updateHover(){
    if (lock) return
    $letter.removeClass('js-active')
    if (itemHover === 0) currentIndex = 0
    $letter.eq(currentIndex).addClass('js-active')
  }

  updateHover()

  /*
  ** ==================== events
  */


  $('html').on('click', function(e){
    const outsideOfNavbar = !$('.navbar')[0].contains(e.target)
    const outsideOfNavdown = !$('.navdown')[0].contains(e.target)
    if (outsideOfNavbar && outsideOfNavdown) {
      forceNavLeave()
    }
  })

  $('html').on('click', '.navbar--burger', updateNav)
  //$('html').on('click', '.navlay--bg', forceNavLeave)
  bus.on('bus.navleave', forceNavLeave)




}

export default burger