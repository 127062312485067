function updateLinks() {
  let protocol = location.protocol
  let hostname = location.hostname
  let port = location.port || ''
  let portdot = port.length ? ':'+port : ''

  $('a[href^="/"]').add('a[href^="./"]').each(function(){
    let $self = $(this)
    let href = $self.attr('href')
      .trimSlash()
      .trimHttp()
      .trimDoubleSlash()
    let buildHref = `${protocol}//${hostname}${portdot}/${href}`
    $self.attr('href', buildHref)
  })

  /*$( 'a' ).each(function() {
    if( location.hostname === this.hostname || !this.hostname.length ) {
        $(this).addClass('local');
    } else {
        $(this).addClass('external');
    }
  });*/
}

export default updateLinks