import wordwrap from "../modules/wordwrap"

const anim = {


  rvlimg : function($el, delay = 0){
    let tl = gsap.timeline()
    tl.from($el,                         1.6, {y:40, ease:"expo.out"}, delay)
    tl.from($el.find('.rvlimg--trans1'), 1.6, {y:'101%', ease:"expo.out"}, delay)
    tl.from($el.find('.rvlimg--trans2'), 1.6, {y:'-101%', ease:"expo.out"}, delay)
  },
  rvlword : function($el, delay = 0, fast = false){
    let tl = gsap.timeline()

    let stag = fast ? 0.05 : 0.1

    let splitter = new wordwrap($el)
    tl.fromTo($el.find('.word--in'), 1.6, {y:'101%'}, {y:'0%', stagger:stag, ease:"expo.out"}, delay)
    tl.add(function(){
      splitter.revert()
    })
  },


  basic : function($el, delay = 0.15){
    let tl = gsap.timeline()
    tl.fromTo($el.find('.anim-opa'),       1.6, {alpha:0},   {alpha:1,  ease:"expo.out"}, delay)
    tl.fromTo($el.find('.anim-scale'),     1.6, {scale:.9},  {scale:1,  ease:"expo.out"}, delay)
    tl.fromTo($el.find('.anim-scaleb'),    1.6, {scale:1.1}, {scale:1,  ease:"expo.out"}, delay)
    tl.fromTo($el.find('.anim-scaley'),    1.6, {scaleY:0},  {scaleY:1, ease:"expo.out"}, delay)
    tl.fromTo($el.find('.anim-transy20'),  1,   {y:20, rotate:'0.001deg'},      {y:0,    clearProps:'transform', ease:"expo.out"}, delay)
    tl.fromTo($el.find('.anim-transy130'),  1,  {y:'130%', rotate:'0.001deg'},  {y:'0%', clearProps:'transform', ease:"expo.out"}, delay)

    tl.fromTo($el.find('.anim-stagopa'),       1.6, {alpha:0},   {alpha:1,  ease:"expo.out", stagger:{each:0.15}}, delay)
    tl.fromTo($el.find('.anim-stagscale'),     1.6, {scale:.9},  {scale:1,  ease:"expo.out", stagger:{each:0.15}}, delay)
    tl.fromTo($el.find('.anim-stagscaleb'),    1.6, {scale:1.1}, {scale:1,  ease:"expo.out", stagger:{each:0.15}}, delay)
    tl.fromTo($el.find('.anim-stagscaley'),    1.6, {scaleY:0},  {scaleY:1, ease:"expo.out", stagger:{each:0.15}}, delay)
    tl.fromTo($el.find('.anim-stagtransy20'),  1,   {y:20, rotate:'0.001deg'},      {y:0,    clearProps:'transform', ease:"expo.out", stagger:{each:0.15}}, delay)
    tl.fromTo($el.find('.anim-stagtransy130'), 1,   {y:'130%', rotate:'0.001deg'},  {y:'0%', clearProps:'transform', ease:"expo.out", stagger:{each:0.15}}, delay)

    tl.fromTo($el.find('.anim-stagopa-fast'),       1.6, {alpha:0},   {alpha:1,  ease:"expo.out", stagger:{amount:0.5}}, delay)
    tl.fromTo($el.find('.anim-stagscale-fast'),     1.6, {scale:.9},  {scale:1,  ease:"expo.out", stagger:{amount:0.5}}, delay)
    tl.fromTo($el.find('.anim-stagscaleb-fast'),    1.6, {scale:1.1}, {scale:1,  ease:"expo.out", stagger:{amount:0.5}}, delay)
    tl.fromTo($el.find('.anim-stagscaley-fast'),    1.6, {scaleY:0},  {scaleY:1, ease:"expo.out", stagger:{amount:0.5}}, delay)
    tl.fromTo($el.find('.anim-stagtransy20-fast'),  1,   {y:20, rotate:'0.001deg'},      {y:0,    clearProps:'transform', ease:"expo.out", stagger:{amount:0.5}}, delay)
    tl.fromTo($el.find('.anim-stagtransy130-fast'), 1,   {y:'130%', rotate:'0.001deg'},  {y:'0%', clearProps:'transform', ease:"expo.out", stagger:{amount:0.5}}, delay)


    /*let $nums = $el.find('.anim-numbers span')
    $nums.each(function(){
      let $self = $(this)
      let num = parseInt($self.html())
      $self.html('0')
      let count = {val: 0}
      tl.fromTo(count, 2, {val:0}, {val:num, ease:'power3.inOut', onUpdate:function(){
        $self.html(Math.round(count.val))
      }}, delay + 0.5)
    })*/
  },

  phathead : function($el, delay = 0){
    let tl = gsap.timeline()
    $el.find('.phathead--title').each(function(){
      tl.fromTo($(this).find('.line'), 1.6, {y:'130%', rotate:'0.001deg'}, {y:'0%', clearProps:'transform', ease:"expo.out", stagger:{each:0.15}}, 0)
    })
    tl.fromTo($el.find('.phathead--cta, .phathead--scroll'), 1.6, {alpha:0, y:20, rotate:'0.001deg'}, {alpha:1, y:0, clearProps:'transform', ease:"expo.out"}, 1.1)
  },

  bhead : function($el, delay = 0){
    let tl = gsap.timeline()
    tl.fromTo($el.find('.bhead--title'), 1.6, {alpha:0, y:20, rotate:'0.001deg'}, {alpha:1, y:0, clearProps:'transform', ease:"expo.out"}, 0)
    tl.fromTo($el.find('.line'), 1.6, {y:'130%', rotate:'0.001deg'}, {y:'0%', clearProps:'transform', ease:"expo.out", stagger:{each:0.15}}, 0.2)
    tl.fromTo($el.find('.bhead--markup, .bhead--video, .share li, .bhead--manifeste, .bhead--cta, .bhead--scroll'), 1.6, {alpha:0, y:20, rotate:'0.001deg'}, {alpha:1, y:0, clearProps:'transform', ease:"expo.out", stagger:{each:0.1}}, 0.8)
    tl.fromTo($el.find('.animc'), 1.6, {alpha:0, scale:0.9, rotate:'0.001deg'}, {alpha:1, scale:1, clearProps:'transform', ease:"expo.out", onStart:function(){
      setTimeout(function(){
        $el.find('.animc').addClass('js-active')
      }, 1600)
    }}, 0.6)
  },

  loadimage: function($el){
    $el.on('load error', function(){
      gsap.to($el, 1, {alpha:1}, 0)
    })
    $el.each(function(){
      let $self = $(this)
      $self.attr('src', $self.attr('data-src'))
      $self.attr('srcset', $self.attr('data-srcset'))
    })
  },

  revealY: function($el, random){
    let del = random ? Math.random()*0.5 : 0
    gsap.killTweensOf($el)
    gsap.fromTo($el, 1.6, {alpha:0, y:20}, {alpha:1, y:0, ease:"expo.out", clearProps:"all", delay:del})
  },

  scrollReveal : function(el) {
    let $el = $(el)
    if ($el.is('[scroll-reveal="basic"]')) {
      anim.basic($el)
    } else if ($el.is('[scroll-reveal="phathead"]')) {
      anim.phathead($el)
    } else if ($el.is('[scroll-reveal="bhead"]')) {
      anim.bhead($el)
    } else if ($el.is('[scroll-reveal="loadimage"]')) {
      anim.loadimage($el)
    } else if ($el.is('[scroll-reveal="postgrid--item"]')) {
      anim.revealY($el)
    } else if ($el.is('[scroll-reveal="ppgrid--item"]')) {
      anim.revealY($el)
    } else if ($el.is('[scroll-reveal="revealY"]')) {
      anim.revealY($el)
    } else if ($el.is('[scroll-reveal="revealY-rand"]')) {
      anim.revealY($el, true)
    } else {
      $el.trigger('reveal')
    }
  }
}

module.exports = anim