import VirtualScroll from 'virtual-scroll'
import normalizeRange from '../base/normalizeRange'
import debounce from 'lodash/debounce';

function busScroll() {

  st.eased = st.current = 0

  if (sniffer.isSimplified || window.scrollType === 'default') {


    $('html').addClass('normalscroll-active')

    $(window).on('scroll', onScroll)
    let timeoutScrollend
    function onScroll() {
      st.eased = st.current = $(window).scrollTop()
      bus.emit('bus.scroll')
      clearTimeout(timeoutScrollend)
      timeoutScrollend = setTimeout(function(){
        bus.emit('bus.scrollend')
      }, 200)
    }


  } else if (window.scrollType === 'virtual') {// virtual scroll


    const isWindows = (["Win32", "Win64", "Windows", "WinCE"].indexOf(window.navigator.platform) !== -1)
    const vs = new VirtualScroll({
      mouseMultiplier: isWindows ? 1.1 : 0.45,
      touchMultiplier: 3,
      firefoxMultiplier: isWindows ? 40 : 90,
      passive: true
    })
    vs.on(onScroll)


    const $scrollCont = $('#js-scroll')
    let $scrollindic = $('.scrollindic')

    $('html').addClass('virtualscroll-active')

    let timeoutScrollend
    function onScroll({ deltaY, deltaX, originalEvent }) {
      if (window.preventScrollNav || window.preventScrollForm || window.preventScrollTransi) return

      let currentDelta = deltaY

      //if (window.currentSlug === 'home' && originalEvent.type === 'touchmove') {
      //  currentDelta = Math.abs(deltaY) > Math.abs(deltaX) ? deltaY : deltaX
      //}

      if (event.shiftKey && event.code === "Space") currentDelta = Math.abs(currentDelta)
      st.current += -currentDelta
      if (st.current < st.min) st.current = 0
      if (st.current > st.max) st.current = st.max
      bus.emit('bus.scroll')

      clearTimeout(timeoutScrollend)
      timeoutScrollend = setTimeout(function(){
        bus.emit('bus.scrollend')
      }, 200)
    }

    function onRaf(){
      if (window.preventScrollTransi) return

      st.eased = utils.lerp(st.eased, st.current, 0.075)
      gsap.set($scrollCont, {y:-st.eased})

      // scroll indicator
      let indicVal = normalizeRange(st.eased, st.min, st.max, 0, wh - (wh*.2))
      gsap.set($scrollindic, {y:indicVal})
    }
    bus.on('bus.raf', onRaf)

    // prevent too much scroll on resize
    function onResize(){
      if (st.current > st.max) st.current = st.max
    }
    bus.on('bus.resize', debounce(onResize, 100))


  } else if (window.scrollType === 'smooth') {// smooth scroll


    $('html').addClass('smoothscroll-active')

    $(window).on('scroll', onScroll)
    let timeoutScrollend
    function onScroll() {
      st.current = $(window).scrollTop()
      bus.emit('bus.scroll')
      clearTimeout(timeoutScrollend)
      timeoutScrollend = setTimeout(function(){
        bus.emit('bus.scrollend')
      }, 200)
    }


    const $scrollCont = $('#js-scroll')
    function onRaf(){
      if (window.preventScrollTransi) return

      st.eased = utils.lerp(st.eased, st.current, 0.125)
      gsap.set($scrollCont, {y:-st.eased})
    }
    bus.on('bus.raf', onRaf)


    // prevent too much scroll on resize
    function onResize(){
      if (st.current > st.max) st.current = st.max
    }
    bus.on('bus.resize', debounce(onResize, 100))
  }
}

export default busScroll