function updateNavActive() {

  let currentURL = document.URL.trimHttp().trimSlash()

  let $link = $('a')
  $link.removeClass('link-current-page')
  $link.each(function(){
    let $self = $(this)
    let linkHref = $self.attr('href')
    let cleanHref

    if (linkHref !== undefined) {
      cleanHref = linkHref.trimHttp().trimSlash()
    }

    if (cleanHref === currentURL) $self.addClass('link-current-page')
  })
}

export default updateNavActive