function pageTransiLeave() {
  bus.emit('bus.pageLeave')

  //$('html').attr('page-slug', '');

  window.preventScrollTransi = true


  // timeline
  let tl = gsap.timeline()

  tl.to($('.pagecont'), 1, {alpha:0, ease:"power3.out"}, 0)
  tl.to($('.foot'), 1, {alpha:0, ease:"power3.out"}, 0)

  tl.fromTo($('.pageload'), 1.5, {y:0, x:'-101%'}, {y:0, x:'-50%', ease:'expo.inOut'}, 0)

  tl.add(function(){
    scrollto(0, true)
    bus.emit('bus.pageLeaveEnd')
    window.preventPageChanging = false
  }, .6)

}

module.exports = pageTransiLeave